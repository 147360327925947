import * as TYPE from './types';

export const initialState = {
	currentPageNo: 1,
};

export default (action, state = initialState) => {
	switch (action.type) {
		case TYPE.PREVIOUS:
			return {
				...state,
				currentPageNo: state.currentPageNo - 1,
			};
		case TYPE.NEXT:
			return {
				...state,
				currentPageNo: state.currentPageNo + 1,
			};
		case TYPE.SET:
			return {
				...state,
				currentPageNo: action.payload,
			};
		default: {
			return state;
		}
	}
};
