import React from 'react';
import { useConsumer } from 'hooks';
import './consumer.css';

function ConsumerItem({ item }) {
	const { handleSetQuantity } = useConsumer();

	const onSetQuantity = ({ key, quantity }) => {
		handleSetQuantity(key, quantity);
	};

	const { key, name, quantity } = item;

	return (
		<tr key={key}>
			<td>{name}</td>
			<td className="text-center">
				<div className="input-group">
					<button
						className="btn btn-outline-secondary"
						type="button"
						onClick={() =>
							onSetQuantity({
								key,
								quantity: quantity - 1,
							})
						}
					>
						-
					</button>
					<input
						type="number"
						className="form-control inputQuantity"
						value={quantity}
						min="0"
						onChange={(e) => {
							onSetQuantity({
								key,
								quantity: e.target.value,
							});
						}}
					/>
					<button
						className="btn btn-outline-secondary"
						type="button"
						onClick={() =>
							onSetQuantity({
								key,
								quantity: quantity + 1,
							})
						}
					>
						+
					</button>
				</div>
			</td>
			{
				// <td className="text-center d-none d-md-table-cell">{startEffect} W</td>
				// <td className="text-center d-none d-md-table-cell">{effect} W</td>
			}
		</tr>
	);
}

export default ConsumerItem;
