import React, { useEffect } from 'react';
import { useProducts, useConsumer, useNavigation } from 'hooks';
import './products.css';

function Products() {
	const { currentPageNo } = useNavigation();
	const { items, handleGet } = useProducts();
	const { calculatedEffect, calculatedStartEffect, hasSensitiveConsumers } =
		useConsumer();

	if (currentPageNo !== 3) return null;

	useEffect(() => {
		handleGet();
	}, []);

	return (
		<div className="row py-5">
			<h2>Produkter</h2>
			<p>Här kommer en lista med de elverk som passar dina behov.</p>

			<div className="row row-cols-1 row-cols-md-3 g-4">
				{items &&
					items
						.sort((a, b) => a.effect - b.effect)
						.filter((item) => item.effect >= calculatedEffect)
						.filter((item) => item.effect >= calculatedStartEffect)
						.filter((item) => item.inverter || !hasSensitiveConsumers)
						.map((item) => (
							<div className="col" key={item.name}>
								<div className="card">
									<img
										src={item.picture}
										className="card-img-top"
										alt={item.name}
									/>
									<div className="card-body">
										<h5 className="card-title">{item.name}</h5>
										<p className="card-text">{item.model}</p>
									</div>
									<ul className="list-group list-group-flush">
										<li className="list-group-item">
											Toppeffekt: {item.topEffect} W
										</li>
										<li className="list-group-item">
											Kontinuerlig effekt: {item.effect} W
										</li>
									</ul>
									<div className="card-body">
										<a
											href={item.url}
											className="card-link"
											target="_blank"
											rel="noreferrer"
										>
											Visa produkt
										</a>
									</div>
								</div>
							</div>
						))}
			</div>
		</div>
	);
}

export default Products;
