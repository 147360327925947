import React from 'react';
import PropTypes from 'prop-types';
import { useNavigation } from 'hooks';

function NavigationItem({ text, pageNo }) {
	const { currentPageNo, handleSetPage } = useNavigation();

	const active = Number(pageNo) === currentPageNo;
	const className = `nav-link nav-links-champion ${active && 'active'}`;
	const ariaCurrent = active ? 'page' : '';

	const btnOnClick = () => handleSetPage(pageNo);

	return (
		<li className="nav-item">
			<button
				type="button"
				className={className}
				aria-current={ariaCurrent}
				onClick={btnOnClick}
			>
				{text}
			</button>
		</li>
	);
}

NavigationItem.propTypes = {
	text: PropTypes.string,
	pageNo: PropTypes.number,
};

NavigationItem.defaultProps = {
	text: '',
	pageNo: 0,
};

export default NavigationItem;
