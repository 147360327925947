import React from 'react';
import { useConsumer, useNavigation } from 'hooks';
import ConsumerItem from './consumerItem';
import './consumer.css';

function Consumer() {
	const { currentPageNo } = useNavigation();

	if (currentPageNo !== 2) return null;

	const { itemsByCategory } = useConsumer();

	return (
		<div className="row py-5">
			<h2>Förbrukare</h2>

			<p>
				Vad skall du driva på ditt elverk? Vänligen notera att lasterna kommer
				adderas. Om du bara har behov av att driva en sak i taget så välj den
				största lasten nedan.
			</p>

			{itemsByCategory &&
				Object.entries(itemsByCategory).map(([category, items]) => (
					<table className="table table-hover" key={category}>
						<thead className="table-light">
							<tr>
								<th scope="col">{category}</th>
								<th
									scope="col"
									className="text-center"
									style={{ width: '150px' }}
								>
									Antal
								</th>
								{/*
								<th
									scope="col"
									className="text-center d-none d-md-table-cell"
									style={{ width: '200px' }}
								>
									Starteffekt
								</th>
								<th
									scope="col"
									className="text-center d-none d-md-table-cell"
									style={{ width: '200px' }}
								>
									Kontinuerlig effekt
								</th>
								*/}
							</tr>
						</thead>
						<tbody>
							{items &&
								items.map((item) => (
									<ConsumerItem key={item.key} item={item} />
								))}
						</tbody>
					</table>
				))}
		</div>
	);
}

export default Consumer;
