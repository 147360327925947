import * as TYPE from './types';

function getPathName() {
	try {
		return decodeURI(window.location.pathname.substring(1));
	} catch (err) {
		return false;
	}
}

export const initialState = {
	error: '',
	items: [],
	isLoading: false,
	redirectTo: '',
	selectedEnvironment: getPathName('environment') || 'Hem & Hushåll',
};

export default (action, state = initialState) => {
	switch (action.type) {
		case TYPE.LOADING:
			return {
				...state,
				isLoading: true,
				error: '',
				redirectTo: '',
			};
		case TYPE.ERROR:
			return {
				...state,
				isLoading: false,
				error: action.payload,
			};
		case TYPE.GET:
			return {
				...state,
				isLoading: false,
				items: action.payload,
			};
		case TYPE.SET_QUANTITY:
			return {
				...state,
				items: state.items.map((item) =>
					item.key === action.payload.key
						? { ...item, quantity: action.payload.quantity }
						: item,
				),
			};
		case TYPE.SET_ENVIRONMENT:
			return {
				...state,
				selectedEnvironment: action.payload.environment,
			};
		default: {
			return state;
		}
	}
};
