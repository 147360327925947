import { parse } from 'papaparse';
import * as TYPE from './types';

function loading() {
	return {
		type: TYPE.LOADING,
	};
}

function get(items) {
	const payload = items.map((item) => {
		return {
			...item,
			effect: parseInt(item.effect, 10),
			inverter: item.inverter === 'JA',
			startCurrent: parseInt(item.startCurrent, 10),
			topEffect: parseInt(item.topEffect, 10),
		};
	});

	return {
		type: TYPE.GET,
		payload,
	};
}

function error(e) {
	return {
		type: TYPE.ERROR,
		payload: e,
	};
}

// eslint-disable-next-line import/prefer-default-export
export function handleGet() {
	return async (dispatch) => {
		dispatch(loading());
		try {
			const url = `${process.env.PUBLIC_URL}/products.csv`;

			parse(url, {
				header: true,
				download: true,
				transformHeader: (header, index) => {
					const headers = [
						'model',
						'name',
						'inverter',
						'startCurrent',
						'topEffect',
						'effect',
						'picture',
						'url',
					];

					return headers[index];
				},
				error: (_err, _file, _inputElem, reason) => {
					dispatch(error(reason));
				},
				complete: (input) => {
					dispatch(get(input.data));
				},
			});
		} catch (e) {
			dispatch(error(e.response.data));
		}
	};
}
