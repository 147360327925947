import React, {
	createContext,
	useContext,
	useReducer,
	useCallback,
	useMemo,
} from 'react';
import PropTypes from 'prop-types';
import { asyncer } from './middlewares';
import mainReducer, { initialState } from './reducers';

const GlobalStore = createContext({});

export const useGlobalStore = () => useContext(GlobalStore);

export default function Provider({ children }) {
	const [state, dispatchBase] = useReducer(mainReducer, initialState);

	const dispatch = useCallback(asyncer(dispatchBase, state), []);

	const providerValue = useMemo(() => ({ state, dispatch }));

	return (
		<GlobalStore.Provider value={providerValue}>
			{children}
		</GlobalStore.Provider>
	);
}

Provider.propTypes = {
	children: PropTypes.node.isRequired,
};
