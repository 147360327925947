/* eslint-disable no-bitwise */
import { parse } from 'papaparse';
import * as TYPE from './types';

function generateKey(str) {
	let hash = 0;
	let i;
	let chr;
	if (str.length === 0) return hash;
	for (i = 0; i < str.length; i += 1) {
		chr = str.charCodeAt(i);
		hash = (hash << 5) - hash + chr;
		hash |= 0; // Convert to 32bit integer
	}
	return hash;
}

function loading() {
	return {
		type: TYPE.LOADING,
	};
}

function get(items) {
	const payload = items.map((item) => {
		return {
			...item,
			key: generateKey(item.environment + item.category + item.name),
			quantity: 0,
			startEffect: parseInt(item.startEffect, 10),
			effect: parseInt(item.effect, 10),
			sensitive: item.sensitive === 'JA',
		};
	});

	return {
		type: TYPE.GET,
		payload,
	};
}

function setQuantity(key, quantity) {
	return {
		type: TYPE.SET_QUANTITY,
		payload: {
			key,
			quantity,
		},
	};
}

function setEnvironment(environment) {
	return {
		type: TYPE.SET_ENVIRONMENT,
		payload: {
			environment,
		},
	};
}

function error(e) {
	return {
		type: TYPE.ERROR,
		payload: e,
	};
}

export function handleGet() {
	return async (dispatch) => {
		dispatch(loading());
		try {
			const url = `${process.env.PUBLIC_URL}/consumer.csv`;

			parse(url, {
				header: true,
				download: true,
				transformHeader: (header, index) => {
					const headers = [
						'name',
						'environment',
						'category',
						'startEffect',
						'effect',
						'sensitive',
					];

					return headers[index];
				},
				error: (_err, _file, _inputElem, reason) => {
					dispatch(error(reason));
				},
				complete: (input) => {
					dispatch(get(input.data));
				},
			});
		} catch (e) {
			dispatch(error(e.response.data));
		}
	};
}

export function handleSetQuantity(key, quantity) {
	return async (dispatch) => {
		const newQuantity = quantity > 0 ? quantity : 0;

		dispatch(setQuantity(key, newQuantity));
	};
}

export function handleSetEnvironment(environment) {
	return async (dispatch) => {
		dispatch(setEnvironment(environment));
	};
}
