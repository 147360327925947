import * as TYPE from './types';

function previousPage() {
	return {
		type: TYPE.PREVIOUS,
	};
}

function nextPage() {
	return {
		type: TYPE.NEXT,
	};
}

function setPage(newPageNo) {
	return {
		type: TYPE.SET,
		payload: newPageNo,
	};
}

function handleScrollToTop() {
	window.scrollTo({ top: 0, behavior: 'smooth' });
}

export function handlePreviousPage() {
	return async (dispatch) => {
		handleScrollToTop();
		dispatch(previousPage());
	};
}

export function handleNextPage() {
	return async (dispatch) => {
		handleScrollToTop();
		dispatch(nextPage());
	};
}

export function handleSetPage(newPageNo) {
	return async (dispatch) => {
		handleScrollToTop();
		dispatch(setPage(newPageNo));
	};
}
