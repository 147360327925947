import React from 'react';
import { Usage } from 'components/usage';
import { Navigation, NavigationPagination } from 'components/navigation';
import { Consumer, ConsumerSummary } from 'components/consumer';
import { Products } from 'components/products';
import Provider from 'store';
import './app.css';

function App() {
	return (
		<Provider>
			<div className="container">
				<div className="row">
					<div className="col-12 col-md-4">
						<Navigation />
					</div>
					<div className="col-12 col-md-8">
						<main>
							<Usage />
							<Consumer />
							<ConsumerSummary />
							<Products />

							<NavigationPagination />
						</main>
					</div>
				</div>
			</div>
		</Provider>
	);
}

export default App;
