import { useGlobalStore } from 'store';
import bindActions from 'store/bindActions';
import reducer from 'store/navigation';

const { actions } = reducer;

const useNavigation = () => {
	const { state, dispatch } = useGlobalStore();

	// List of Props
	const { currentPageNo } = state.navigation;

	// List of Actions
	const { handlePreviousPage, handleNextPage, handleSetPage } = actions;

	// Bind Actions
	const boundActions = bindActions(
		{
			handlePreviousPage,
			handleNextPage,
			handleSetPage,
		},
		dispatch,
	);

	return { currentPageNo, ...boundActions };
};

export default useNavigation;
