import React from 'react';
import { useConsumer, useNavigation } from 'hooks';
import './consumer.css';

function ConsumerSummary() {
	const { currentPageNo } = useNavigation();
	const { items, calculatedEffect, calculatedStartEffect } = useConsumer();

	if (currentPageNo !== 3) return null;

	return (
		<div className="row py-5">
			<h3>Summering förbrukare</h3>
			<table className="table table-hover">
				<thead className="table-light">
					<tr>
						<th scope="col">Namn</th>
						<th scope="col" className="text-center" style={{ width: '150px' }}>
							Antal
						</th>
						<th scope="col" className="text-center" style={{ width: '200px' }}>
							Starteffekt
							<br />
							Kontinuerlig effekt
						</th>
					</tr>
				</thead>
				<tbody>
					{items &&
						items
							.filter((item) => item.quantity > 0)
							.map((item) => (
								<tr key={item.key}>
									<td>{item.name}</td>
									<td className="text-center">{item.quantity} st</td>
									<td className="text-center">
										{item.startEffect} W<br />
										{item.effect} W
									</td>
								</tr>
							))}
				</tbody>
			</table>

			<p>
				Ditt uppskattade effektbehov är <b>{calculatedEffect} W</b> med en
				toppeffekt på <b>{calculatedStartEffect} W</b>.
			</p>
		</div>
	);
}

export default ConsumerSummary;
