import { useGlobalStore } from 'store';
import bindActions from 'store/bindActions';
import reducer from 'store/consumer';

const { actions } = reducer;

const useConsumer = () => {
	const { state, dispatch } = useGlobalStore();

	// List of Props
	const { items, error, isLoading, selectedEnvironment } = state.consumer;

	// List of Actions
	const { handleGet, handleAdd, handleSetQuantity, handleSetEnvironment } =
		actions;

	// Bind Actions
	const boundActions = bindActions(
		{
			handleGet,
			handleAdd,
			handleSetQuantity,
			handleSetEnvironment,
		},
		dispatch,
	);

	const hasSensitiveConsumers = items
		.filter((item) => item.quantity > 0)
		.some((item) => item.sensitive);

	// Calculate usage
	const { calculatedEffect, calculatedStartEffect } = items
		.filter((item) => item.quantity > 0)
		.sort((a, b) => {
			return a.effect - b.effect; // Sort by largest effect first
		})
		.reduce(
			(result, item) => {
				const newResult = result;
				const { effect, startEffect, quantity } = item;

				const adjustedStartEffect = startEffect > 0 ? startEffect : effect;

				newResult.calculatedEffect += effect * quantity;
				newResult.calculatedStartEffect =
					newResult.calculatedEffect - effect + adjustedStartEffect;

				return newResult;
			},
			{ calculatedEffect: 0, calculatedStartEffect: 0 },
		);

	const environments = [...new Set(items.map((item) => item.environment))];

	const itemsByCategory = items
		.filter((item) => item.environment === selectedEnvironment)
		.reduce((acc, item) => {
			const newAcc = acc;
			const { category } = item;

			if (!newAcc[category]) newAcc[category] = [];

			newAcc[category] = [...newAcc[category], item];

			return newAcc;
		}, {});

	return {
		items,
		itemsByCategory,
		error,
		isLoading,
		selectedEnvironment,
		hasSensitiveConsumers,
		calculatedEffect,
		calculatedStartEffect,
		environments,
		...boundActions,
	};
};

export default useConsumer;
