import React from 'react';
import { useConsumer } from 'hooks';
import './consumer.css';

function ConsumerEnvironmentFilter() {
	const { environments, selectedEnvironment, handleSetEnvironment } =
		useConsumer();

	const onSetEnvironment = (Environment) => {
		handleSetEnvironment(Environment);
	};

	return (
		<div className="consumerEnvironmentFilter">
			<div className="btn-group flex-wrap" role="group" aria-label="Miljö">
				{environments &&
					environments.map((environment) => {
						let className = 'btn btn-outline-champion';

						if (selectedEnvironment === environment) className += ' active';

						return (
							<button
								key={environment}
								type="button"
								className={className}
								onClick={() => onSetEnvironment(environment)}
							>
								{environment}
							</button>
						);
					})}
			</div>
		</div>
	);
}

export default ConsumerEnvironmentFilter;
