import consumerReducer from './consumer';
import productReducer from './products';
import navigationReducer from './navigation';
import { logger } from './middlewares';

export const initialState = {
	consumer: consumerReducer.initialState,
	products: productReducer.initialState,
	navigation: navigationReducer.initialState,
};

export default function mainReducer(state, action) {
	// Receiving previous state here
	const { consumer, products, navigation } = state;

	// Receiving current state here
	const currentState = {
		consumer: consumerReducer.reducer(action, consumer),
		products: productReducer.reducer(action, products),
		navigation: navigationReducer.reducer(action, navigation),
	};

	// Middlewares
	logger(action, state, currentState);

	return currentState;
}
