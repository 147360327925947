import React from 'react';
import { useNavigation } from 'hooks';

function NavigationPagination() {
	const { currentPageNo, handlePreviousPage, handleNextPage } = useNavigation();

	const btnPrevClass = `page-item ${currentPageNo <= 1 && 'disabled'}`;
	const btnNextClass = `page-item ${currentPageNo >= 3 && 'disabled'}`;

	return (
		<nav aria-label="Page navigation">
			<ul className="pagination justify-content-center">
				<li className={btnPrevClass}>
					<button
						type="button"
						className="page-link"
						onClick={handlePreviousPage}
					>
						Föregående
					</button>
				</li>
				<li className={btnNextClass}>
					<button type="button" className="page-link" onClick={handleNextPage}>
						Nästa
					</button>
				</li>
			</ul>
		</nav>
	);
}

export default NavigationPagination;
