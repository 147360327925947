import React, { useEffect } from 'react';
import { ConsumerEnvironmentFilter } from 'components/consumer';
import { useConsumer, useNavigation } from 'hooks';

function Usage() {
	const { currentPageNo } = useNavigation();

	if (currentPageNo !== 1) return null;

	const { handleGet } = useConsumer();

	useEffect(() => {
		handleGet();
	}, []);

	return (
		<div className="row py-5 text-center">
			<h1>Välj rätt elverk</h1>
			<p>
				Använd vår guide för att komma fram till det rätta elverket för dig.
				Börja med att välja i vilken miljö du vill använda elverket.
			</p>

			<ConsumerEnvironmentFilter />
		</div>
	);
}

export default Usage;
