import React from 'react';
import NavigationItem from './navigationItem';

function Navigation() {
	return (
		<div className="row py-5">
			<ul className="nav nav-pills flex-column nav-fill">
				<NavigationItem pageNo={1} text="1. Välj användningsområde" />
				<NavigationItem pageNo={2} text="2. Välj behov" />
				<NavigationItem pageNo={3} text="3. Elverk som passar dig" />
			</ul>
		</div>
	);
}

export default Navigation;
